<template>
  <header class="header">
    <div class="container">
      <div class="header__nav">
        <router-link class="header__logo" :to="{ name: 'relay' }">
          <img src="@/assets/images/logo.svg" alt="" />
        </router-link>

        <RockHeaderMenu />

        <div class="header__other">
          <RockHeaderLang />

          <RockHeaderNotification />

          <RockHeaderWallet :balance="balance.amount" />

          <RockHeaderUserMenu />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import RockHeaderMenu from "@/components/RockHeaderMenu";
import RockHeaderWallet from "@/components/RockHeaderWallet";
import RockHeaderUserMenu from "@/components/RockHeaderUserMenu";
import RockHeaderNotification from "@/components/RockHeaderNotification.vue";
import RockHeaderLang from "@/components/RockHeaderLang.vue";
export default {
  name: "RockHeader",
  components: {
    RockHeaderLang,
    RockHeaderNotification,
    RockHeaderUserMenu,
    RockHeaderWallet,
    RockHeaderMenu,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      balance: "auth/balance",
    }),
  },
};
</script>

<style scoped lang="scss">
.header__logo img {
  width: 180px;
}
</style>
