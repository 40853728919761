import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/login",
    name: "login",
    meta: { layout: "auth", auth: false },
    component: () => import("@/views/auth/Login"),
  },
  {
    path: "/register",
    name: "register",
    meta: { layout: "auth", auth: false },
    component: () => import("@/views/auth/Register"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: { layout: "auth", auth: false },
    component: () => import("@/views/auth/ForgotPassword"),
  },
  {
    path: "/forgot-password/:token",
    name: "forgot-password-form",
    meta: { layout: "auth", auth: false },
    component: () => import("@/views/auth/ForgotPasswordReset"),
  },

  {
    path: "/verify-email/:status",
    name: "verify-email-message",
    meta: { layout: "auth", auth: false },
    component: () => import("@/views/auth/VerifyEmailMessage"),
  },
  {
    path: "/loginAs/:username",
    name: "loginAs",
    meta: { layout: "auth", auth: true },
    component: () => import("@/views/auth/LoginAs"),
  },

  {
    path: "/r/:referral",
    name: "referral",
    meta: { layout: "auth", auth: false },
    component: () => import("@/views/auth/Register"),
  },

  {
    path: "/profile",
    name: "profile",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/auth/Profile"),
  },

  {
    path: "/academy",
    name: "academy",
    meta: { layout: "main", title: "Relax Academy", auth: true },
    component: () => import("@/views/academy/IndexPage"),
  },
  {
    path: "/income",
    name: "income",
    meta: { layout: "main", title: "Доход", auth: true },
    component: () => import("@/views/academy/IncomePage"),
  },
  {
    path: "/help",
    name: "help",
    meta: { layout: "main", title: "Помощь", auth: true },
    component: () => import("@/views/academy/HelpAdministrationPage"),
  },
  {
    path: "/help-partner",
    name: "help-partner",
    meta: { layout: "main", title: "Помощь", auth: true },
    component: () => import("@/views/academy/HelpPartnerPage"),
  },
  {
    path: "/exchange",
    name: "exchange",
    meta: { layout: "main", title: "Фонд обмена", auth: true },
    component: () => import("@/views/academy/ExchangePage"),
  },
  {
    path: "/",
    name: "relay",
    meta: { layout: "main", title: "Together To Success", auth: true },
    component: () => import("@/views/RelayPage.vue"),
  },
  {
    path: "/finance",
    name: "finance",
    meta: { layout: "main", title: "Мои финансы", auth: true },
    component: () => import("@/views/finance/IndexPage"),
  },
  {
    path: "/finance/replenish",
    name: "replenish",
    meta: { layout: "main", title: "Пополнить", auth: true },
    component: () => import("@/views/finance/ReplenishPage"),
  },
  {
    path: "/finance/withdraw",
    name: "withdraw",
    meta: { layout: "main", title: "Вывести", auth: true },
    component: () => import("@/views/finance/WithdrawPage"),
  },
  {
    path: "/finance/withdraw",
    name: "withdraw",
    meta: { layout: "main", title: "Вывести", auth: true },
    component: () => import("@/views/finance/WithdrawPage"),
  },
  {
    path: "/finance/transfer",
    name: "transfer",
    meta: { layout: "main", title: "Внутренние переводы", auth: true },
    component: () => import("@/views/finance/TransferPage"),
  },

  {
    path: "/course/:slug",
    name: "course",
    meta: { layout: "main", title: "Course", auth: true },
    component: () => import("@/views/academy/CoursePage"),
  },
  {
    path: "/course/:slug/:slugItem",
    name: "course-single",
    meta: { layout: "main", title: "CourseSingle", auth: true },
    component: () => import("@/views/academy/CourseSinglePage"),
  },

  {
    path: "/travel",
    name: "travel",
    meta: { layout: "main", title: "Relax Travel", auth: true },
    component: () => import("@/views/travel/IndexPage"),
  },
  {
    path: "/shop",
    name: "shop",
    meta: { layout: "main", title: "Relax Shop", auth: true },
    component: () => import("@/views/shop/IndexPage"),
  },
  {
    path: "/dating",
    name: "dating",
    meta: { layout: "main", title: "Relax Dating", auth: true },
    component: () => import("@/views/dating/IndexPage"),
  },

  {
    path: "/team",
    name: "team",
    meta: { layout: "main", title: "My Team", auth: true },
    component: () => import("@/views/MyTeamPage"),
  },
  {
    path: "/certificates",
    name: "certificates",
    meta: { layout: "main", title: "Certificates", auth: true },
    component: () => import("@/views/mlm/CertificatePage.vue"),
  },
  {
    path: "/mlm",
    name: "mlm",
    meta: { layout: "main", title: "MLM & BUSINESS", auth: true },
    component: () => import("@/views/MlmAndBusinessPage"),
  },
  {
    path: "/mlm/consultant",
    name: "mlm-consultants",
    meta: { layout: "main", title: "Mlms consultant", auth: true },
    component: () => import("@/views/mlm/ConsultantsPage"),
  },
  {
    path: "/mlm/virus",
    name: "mlm-virus",
    meta: { layout: "main", title: "Virus of Success", auth: true },
    component: () => import("@/views/virus/VirusPage.vue"),
  },
  {
    path: "/mlm/aggregator",
    name: "mlm-aggregator",
    meta: { layout: "main", title: "Recruiting aggregator", auth: true },
    component: () => import("@/views/aggregator/AggregatorPage.vue"),
  },
  {
    path: "/mlm/aggregator/request",
    name: "mlm-aggregator-request",
    meta: {
      layout: "main",
      title: "Recruiting aggregator Request",
      auth: true,
    },
    component: () => import("@/views/aggregator/AggregatorRequestPage.vue"),
  },
  {
    path: "/mlm/consultant/:username",
    name: "mlm-consultant-single",
    meta: { layout: "main", title: "Mlm consultant Page", auth: true },
    component: () => import("@/views/mlm/ConsultantSinglePage"),
  },

  {
    path: "/mlm",
    name: "my-mlms",
    meta: { layout: "main", title: "Mlms", auth: true },
    component: () => import("@/views/mlm/MyMlmsPage"),
  },
  {
    path: "/mlm/create",
    name: "mlm-create",
    meta: { layout: "main", title: "Mlm Create", auth: true },
    component: () => import("@/views/mlm/CreateForm"),
  },
  {
    path: "/mlm/:slug/update",
    name: "mlm-update",
    meta: { layout: "main", title: "Mlm Update", auth: true },
    component: () => import("@/views/mlm/UpdateForm"),
  },
  {
    path: "/mlm/:slug",
    name: "mlm-single",
    meta: { layout: "main", title: "MLM Partners", auth: true },
    component: () => import("@/views/mlm/SinglePage"),
  },
  {
    path: "/mlm-info/:slug",
    name: "mlmPage",
    meta: { layout: "main", title: "Страница", auth: true },
    component: () => import("@/views/mlm/MlmTextPage"),
  },

  {
    path: "/company",
    name: "my-companies",
    meta: { layout: "main", title: "Companies", auth: true },
    component: () => import("@/views/company/MyCompaniesPage"),
  },
  {
    path: "/company/create",
    name: "company-create",
    meta: { layout: "main", title: "Company Create", auth: true },
    component: () => import("@/views/company/CreateForm"),
  },
  {
    path: "/company/:slug/update",
    name: "company-update",
    meta: { layout: "main", title: "Company Update", auth: true },
    component: () => import("@/views/company/UpdateForm"),
  },
  {
    path: "/company/:slug",
    name: "company-single",
    meta: { layout: "main", title: "Companies", auth: true },
    component: () => import("@/views/company/SinglePage"),
  },

  {
    path: "/news",
    name: "news",
    meta: { layout: "main", title: "Новости", auth: true },
    component: () => import("@/views/news/NewsListPage"),
  },
  {
    path: "/news/:slug",
    name: "news-item",
    meta: { layout: "main", title: "Новости", auth: true },
    component: () => import("@/views/news/NewsItemPage"),
  },

  {
    path: "/notification",
    name: "notifications",
    meta: { layout: "main", title: "Уведомления", auth: true },
    component: () => import("@/views/notification/ListPage"),
  },

  {
    path: "/support",
    name: "support",
    meta: { layout: "main", title: "Поддержка", auth: true },
    component: () => import("@/views/support/IndexPage"),
  },

  {
    path: "/card-buy",
    name: "card-buy",
    meta: { layout: "main", title: "Покупка карты", auth: true },
    component: () => import("@/views/cardBuyPage"),
  },
  {
    path: "/business-card",
    name: "business-card",
    meta: { layout: "main", title: "Визитная карта", auth: true },
    component: () => import("@/views/businessCardPage"),
  },
  {
    path: "/:slug",
    name: "page",
    meta: { layout: "main", title: "Страница", auth: true },
    component: () => import("@/views/textPage"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    meta: { layout: "main", auth: false },
    component: () => import("@/views/404"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
